:host ::ng-deep .mat-horizontal-stepper-header-container {
	display: none !important;
}

.mat-horizontal-content-container {
	padding: 0 !important;
	overflow: visible !important;
}

.stepper-class {
	.mat-horizontal-stepper-wrapper {
		flex-direction: row;
		display: flex;
		background-color: white;

		.mat-horizontal-stepper-header-container {
			display: flex;
			flex-direction: column !important;
			overflow-y: auto;
			align-items: flex-start !important;
			justify-content: center;
			min-height: 30%;
			height: 50% !important;
			padding-right: 0.75rem;
			z-index: 20;
			padding-left: 1.5rem;
			background-color: white;
			width: 100% !important;

			.mat-step-header {
				padding: 0;
				width: 100% !important;

				.mat-step-label {
					color: black;
					width: 100% !important;

					.mat-step-text-label {
						width: 100% !important;
					}
				}

				.mat-step-icon {
					background-color: white;
					border: 2px solid $primary-1;
					color: $primary-1;
				}
			}

			.mat-stepper-horizontal-line {
				transform: rotate(90deg);
				position: relative;
				left: 0.25rem;
				width: 3rem;
				z-index: 2;
				border-top-width: 2px;
				border-top-style: dashed;
			}
		}

		.mat-horizontal-content-container {
			border-left: 2px solid $gray-shade-4;

			.batch-form {
				padding-left: 1.5rem;
				padding-top: 1.5rem;
				min-height: 39rem;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				max-width: 36.25rem;
				max-height: 39rem;
				overflow: hidden;

				.batch-form-content {
					overflow: scroll;
					display: flex;
					flex-direction: column;
				}
			}
		}
	}
}

.add-feedback {
	.mat-horizontal-stepper-wrapper {
		.mat-horizontal-stepper-header-container {
			.mat-step-header {
				.mat-ripple {
					display: none !important;
				}

				.mat-step-label-active {
					background-color: transparent !important;
				}
			}

			.mat-step-header:focus {
				background-color: transparent !important;
				box-shadow: none !important;
			}
		}

		.mat-horizontal-content-container {
			.batch-form {
				.batch-form-content {
					width: 461px;
				}
			}
		}
	}
}

.level-status-card {
	.mat-horizontal-stepper-wrapper {
		.mat-horizontal-stepper-header-container {
			.mat-step-header {
				.mat-step-icon {
					background: none;
					border: 1px solid $primary-1 !important;
				}

				.mat-step-icon-state-edit {
					border: 1px solid $gray-shade-7 !important;
				}
			}
		}
	}
}

.pip-status-card {
	.mat-horizontal-stepper-wrapper {
		.mat-horizontal-stepper-header-container {
			.mat-step-header {
				.mat-step-icon {
					background: none;
					border: 1px solid $error-1 !important;
				}
			}
		}
	}
}

.confirmed-status-card {
	.mat-horizontal-stepper-wrapper {
		.mat-horizontal-stepper-header-container {
			.mat-step-header {
				.mat-step-icon {
					background: none;
					border: 1px solid $success-3 !important;
				}
			}
		}
	}
}

.unmark-status-card {
	.mat-horizontal-stepper-wrapper {
		.mat-horizontal-stepper-header-container {
			.mat-step-header {
				.mat-step-icon {
					background: none;
					border: 1px solid $warning-3 !important;
				}
			}
		}
	}
}

.status-card {
	.mat-horizontal-stepper-wrapper {
		.mat-horizontal-stepper-header-container {
			overflow-x: auto !important;
			white-space: nowrap !important;
			display: flex;
			scroll-behavior: smooth;
			border-bottom: none;

			.mat-step-header {
				background: transparent !important;
				min-width: max-content !important;
				padding: 1rem !important;
				padding-bottom: 0.5rem !important;

				.mat-step-label {
					.mat-chip {
						.cancelled-stage {
							text-decoration-thickness: 2px;
						}
					}
				}

				.mat-step-icon {
					background: none;
					border: 1px solid $primary-1;
				}

				.mat-step-icon-state-edit {
					border: 1px solid $gray-shade-7 !important;
				}

				.mat-step-header-ripple {
					display: none;
				}

				.navigation-icons {
					padding: 1rem;

					.mat-icon {
						transform: scale(1.75);
						z-index: 10 !important;
					}

					.left-action-btn {
						position: absolute;
						left: 1rem;
					}

					.righ-action-btn {
						position: absolute;
						right: 1rem;
					}
				}
			}

			.mat-stepper-horizontal-line {
				border-top-style: dashed !important;
				max-width: min-content;
				min-width: 3rem;
				top: 31px !important;
			}

			.mat-step-header-selected {
				background-color: transparent !important;
			}

			.mat-horizontal-stepper-header:not(:first-child)::before,
			.mat-horizontal-stepper-header:not(:last-child)::after {
				border-top-style: dashed;
				top: 31px !important;
			}

			.mat-step-header:hover {
				background: transparent !important;
			}
		}

		.mat-horizontal-stepper-header-container::-webkit-scrollbar {
			display: none;
		}

		.mat-horizontal-content-container {
			.status-container {
				.status-details-container {
					.status-actions-container {
						.actions {
							.mat-icon {
								cursor: pointer !important;
							}
						}
					}
				}
			}
		}
	}
}
