@import "../variables";

.mat-slider {
	width: 14.875rem;

	&.mat-accent {
		.mat-slider-track-fill {
			background-color: $primary-1 !important;
		}
	}

	&-horizontal {
		.mat-slider-wrapper {
			top: 21px !important;

			.mat-slider-track-wrapper {
				height: 0.5rem !important;
				border-radius: 0.25rem;
				left: -4px;

				.mat-slider-track-background {
					min-height: 0.5rem;
				}

				.mat-slider-track-fill {
					min-height: 0.5rem;
				}
			}
		}
	}

	&-thumb-container {
		top: 0.375rem !important;

		.mat-slider-thumb {
			background-color: $primary-2 !important;
			border: 2px solid $primary-1 !important;
			height: 24px !important;
			width: 24px !important;
		}
	}

	&.cdk-mouse-focused {
		.mat-slider-thumb {
			transform: none !important;
		}
	}
}

.ngx-slider {
	margin-top: 0.75rem !important;

	&-pointer {
		border: 1px solid $primary-1 !important;
		background-color: $primary-2 !important;
		max-width: 1.5rem;
		max-height: 1.5rem;
		top: -0.5rem !important;

		&::after {
			content: none !important;
		}
	}

	&-bar {
		height: 0.5rem !important;
		border-radius: 0.25rem !important;
		background: $gray-shade-4;
	}

	&-selection {
		background: $primary-1 !important;
	}

	&-bubble {
		bottom: 0 !important;
		top: 1rem !important;

		@include font-montserrat($weight: 600);
		@include typography($size: 14px);

		color: $gray-shade-10 !important;
	}
}
