@import "../variables";
@import "../base/font";

img {
	&.default {
		border-radius: 50%;
		padding: 0 !important;
	}

	&.focussed {
		border: 4px solid $secondary-shade-4;
		border-radius: 50%;
		padding: 0 !important;
	}

	&.small {
		height: 24px;
		width: 24px;
	}

	&.medium {
		height: 32px;
		width: 32px;
	}

	&.large {
		height: 40px;
		width: 40px;
	}

	&.larger {
		height: 48px;
		width: 48px;
	}

	&.largest {
		height: 56px;
		width: 56px;
	}
}

.no-image-small {
	border-radius: 50%;
	background-color: $gray-shade-2;
	border: 1px solid $gray-shade-4;
	height: 24px;
	width: 24px;
	display: flex;
	justify-content: center;
	align-items: center;

	@include font-roboto($weight: 400, $size:13px, $color: $gray-shade-10);

	p {
		font-size: 13px;
	}
}

.no-image-medium {
	border-radius: 50%;
	background-color: $gray-shade-2;
	border: 1px solid $gray-shade-4;
	height: 32px;
	width: 32px;
	display: flex;
	justify-content: center;
	align-items: center;

	@include font-roboto($weight: 400, $size:13px, $color: $gray-shade-10);

	p {
		font-size: 16px;
	}
}

.no-image-large {
	border-radius: 50%;
	background-color: $gray-shade-2;
	border: 1px solid $gray-shade-4;
	height: 40px;
	width: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: Roboto, Helvetica, Arial, sans-serif;

	p {
		font-size: 18px;
	}
}

.no-image-larger {
	border-radius: 50%;
	background-color: $gray-shade-2;
	height: 56px;
	width: 56px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: Roboto, Helvetica, Arial, sans-serif;

	p {
		font-size: 20px;
	}
}

.no-image-largest {
	border-radius: 50%;
	background-color: $gray-shade-2;
	height: 56px;
	width: 56px;
	display: flex;
	justify-content: center;
	align-items: center;

	p {
		font-size: 20px;
	}
}

.no-image-small-focussed {
	border-radius: 50%;
	background-color: $gray-shade-2;
	border: 4px solid $gray-shade-3;
	height: 24px;
	width: 24px;
	display: flex;
	justify-content: center;
	align-items: center;

	p {
		font-size: 13px;
	}
}

.no-image-medium-focussed {
	border-radius: 50%;
	background-color: $gray-shade-2;
	border: 4px solid $gray-shade-3;
	height: 32px;
	width: 32px;
	display: flex;
	justify-content: center;
	align-items: center;

	p {
		font-size: 16px;
	}
}

.no-image-large-focussed {
	border-radius: 50%;
	background-color: $gray-shade-2;
	border: 4px solid $gray-shade-4;
	height: 40px;
	width: 40px;
	display: flex;
	justify-content: center;
	align-items: center;

	p {
		font-size: 18px;
	}
}

.no-image-larger-focussed {
	border-radius: 50%;
	background-color: $gray-shade-2;
	border: 4px solid $gray-shade-4;
	height: 48px;
	width: 48px;
	display: flex;
	justify-content: center;
	align-items: center;

	p {
		font-size: 20px;
	}
}

.no-image-largest-focussed {
	border-radius: 50%;
	background-color: $gray-shade-2;
	border: 4px solid $gray-shade-4;
	height: 56px;
	width: 56px;
	display: flex;
	justify-content: center;
	align-items: center;

	p {
		font-size: 20px;
	}
}

.small {
	border-radius: 16px;
	background-color: $gray-shade-4;
	height: 24px;
	width: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.count-avatar {
	background-color: $secondary-shade-5;
}
