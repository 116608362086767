@import "../variables";
@import "../base/mixins";

.mat-tab-group {
	flex: 1;
	height: 100%;

	.mat-tab-body-wrapper {
		flex: 1;
	}
}

.mat-tab-header {
	border: none;
	width: fit-content;

	.mat-tab-label {
		@include font-montserrat($weight: 600, $size: 0.875rem, $color: $gray-shade-7);

		border-bottom: 2px solid $gray-shade-4 !important;
		background-color: transparent !important;
		opacity: 1;
		height: 44px !important;
		padding: 0.75rem 52px !important;
		border-radius: 4px 4px 0 0 !important;
		max-width: 12.25rem !important;

		&-active {
			background: $secondary-shade-5 !important;
			color: $primary-1 !important;
		}
	}

	.mat-tab-body {
		height: 100%;
	}
}

.batch-details-page {
	.page-body {
		.pip-selected,
		.error {
			.mat-tab-label-container {
				display: none;
			}
		}
	}
}

.candidate-details-tabs-container {
	.mat-tab-group {
		.mat-tab-body-wrapper {
			.mat-tab-body {
				.mat-tab-body-content {
					.pip-feedback-container {
						overflow: hidden;
					}

					.score-feedback-container {
						overflow: hidden;
					}
				}
			}
		}
	}
}
