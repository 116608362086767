.mat-accordion {
	.mat-expansion-panel-spacing {
		&:last-child {
			margin-bottom: 0.5rem !important;
		}
	}

	.mat-expansion-panel {
		display: contents;

		&:last-of-type {
			margin-bottom: 0.5rem;
		}

		&-header {
			padding: 0;

			&-title {
				display: grid;
				grid-template-columns: auto 1fr;
				gap: 0.5rem;
				align-items: center;
				margin-right: 0.5rem;
				width: 100%;
			}

			&:hover {
				background-color: transparent !important;
			}
		}

		&-body {
			padding: 1rem 0;
		}

		.mat-expanded {
			&:hover,
			&:focus {
				background: none;
			}
		}
	}
}

.score-accordian {
	.mat-expansion-indicator::after {
		margin: 2px;
	}
}
