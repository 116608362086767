@import "../variables";
@import "../base/mixins";
@import "../common-style/shadows";

mat-card {
	box-shadow: $shadow-sm;
	padding-left: 8px;
	border-radius: 8px;
	padding-right: 8px;

	.mat-card-title {
		@include font-montserrat($size: 18px, $weight: 600, $color: $gray-shade-10);
	}

	.mat-card-subtitle {
		@include font-roboto($size: 13px, $weight: 400, $color: $gray-shade-6);
	}

	.mat-card-header {
		.mat-card-header-text {
			margin: 0 !important;
		}
	}
}

.images-wrapper {
	padding: 0.5rem 0;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.images-list {
	display: inline-flex;
	flex-direction: row;
	justify-content: flex-start;

	.avatar {
		margin: 0 0 0 -8px;
		overflow: hidden;
		border-radius: 1px solid;
	}
}

.mat-card:hover {
	.mat-focus-indicator {
		visibility: initial !important;
	}

	.mat-focus-indicator:hover {
		svg {
			path {
				stroke: $primary-1 !important;
			}
		}
	}
}

.mat-card:not(.disable-hover):hover {
	border: thin solid $primary-1;
	cursor: pointer;
}

.score-card {
	padding-bottom: 0 !important;

	.card-container {
		.score-card-header {
			display: flex;
			justify-content: space-between;
		}

		.sub-card-container {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-column-gap: 0.75rem;
			margin-bottom: 1px solid $gray-shade-4;

			.sub-card {
				padding: 0.5rem 0.75rem;
				border: 1px solid $gray-shade-4;
				border-radius: 0.5rem;
				display: flex;
				flex-direction: column;
				gap: 0.25rem;
				min-height: 4rem;

				.subcard-subtite {
					font-size: 0.81rem !important;
					margin: 0 !important;
				}
			}
		}

		.score-content {
			display: flex;
			flex-direction: column;
		}

		.score-category {
			display: flex;
			justify-content: space-between;
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;
		}

		.score-divider {
			margin: 0.5rem 0;
		}
	}

	.card-footer {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 0.25rem;
		padding: 0.5rem 1rem;

		.feedback-icon {
			padding: 0.375rem;
			display: flex;
		}
	}
}

.top-performers-container {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 0.875rem;
	margin-bottom: 1px;

	.top-performer-card {
		display: flex !important;
		gap: 0.25rem;
		padding: 0.5rem !important;

		.top-performer-img-section {
			padding: 0.75rem 0.75rem 0.125rem;
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: -0.5rem;
			border-radius: 0.25rem;

			.top-performer-rank {
				padding: 0.125rem 0.5rem;
				color: white;
				font-weight: 500;
				font-size: 13px;
				line-height: 120%;
				border-radius: 6px;
				transform: translateY(-10px);
			}
		}

		.top-performer-profile-section {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			padding: 8px 12px;
			gap: 0.125rem;
			width: 100%;
			border-radius: 0.25rem;

			h6 {
				margin: 0 !important;
			}
		}

		.empty-performer-profile-section {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			padding: 8px 12px;
			gap: 0.5rem;
			width: 100%;
			border-radius: 0.25rem;
		}
	}
}
