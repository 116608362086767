/* You can add global styles to this file, and also import other style files */
@import "./material/material-theme";
@import "./variables";
@import "./base/font";
@import "./base/mixins";
@import "./base/document";
@import "./common-style/typography";
@import "./common-style/button";
@import "./common-style/badge";
@import "./common-style/card";
@import "./common-style/data-grid";
@import "./common-style/input";
@import "./common-style/tab-group";
@import "./common-style/shadows";
@import "./common-style/loader";
@import "./common-style/empty-state-card";
@import "./common-style/checkbox";
@import "./common-style/select-box";
@import "./common-style/dialog";
@import "./common-style/chip";
@import "./common-style/snackbar";
@import "./common-style/tooltip";
@import "./common-style/drawer";
@import "./common-style/datepicker";
@import "./common-style/avatar";
@import "./base/z-index";
@import "./common-style/radio-button";
@import "./common-style/filter-sidebar";
@import "./common-style/stepper";
@import "./common-style/accordian";
@import "./common-style/slider";

// disable the back and next button of pagination
.pagination-disabled {
	color: $gray-shade-5;

	.mat-icon {
		svg {
			path {
				stroke: $gray-shade-5;
			}
		}
	}
}

.text-valid {
	.mat-icon {
		svg {
			path {
				stroke: $success-3 !important;
			}
		}
	}
}

.feedback-score-header {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.close-icon {
		svg {
			path {
				stroke: $gray-shade-6;
			}
		}
	}
}

// .score-chip-component {
// display: flex;
// padding: 15.5px 24px !important;
// 	padding-left: 3rem !important;
// }

.validation-msg {
	font-family: Roboto, Helvetica, Arial, sans-serif;
	font-size: 0.875rem;
	color: $error-1;
	font-weight: 400;
	line-height: 150%;
	margin-top: 0 !important;
	transform: translateY(-15px);
}

.candidate-status-details {
	h6 {
		margin: 0.25rem;
	}
}

.status-dropdown-container {
	max-height: 1.5rem;

	.status-dropdown {
		.mat-form-field-wrapper {
			.mat-form-field-flex {
				max-width: 9.25rem;
				max-height: 24px;
				min-height: unset !important;
				padding: 2px 8px;
				align-items: center;
				justify-content: center;
				border: 1px solid $gray-shade-5 !important;
				border-radius: 1rem !important;
				gap: 4px;
				font-size: 14px;
				margin: 0 !important;

				.mat-form-field-prefix {
					.mat-icon {
						display: flex !important;
						width: 0.5rem !important;
						height: 0.5rem !important;
					}
				}
			}
		}
	}
}

.hide-tabgroup {
	.mat-tab-label {
		display: none !important;
	}

	.search-menu {
		position: unset !important;
		width: 100%;
		max-height: 2.75rem;
	}
}
