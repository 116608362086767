// Roboto
@mixin font-roboto($size: false, $color: false, $weight: false, $lh: false) {
	font-family: Roboto, Helvetica, Arial, sans-serif;

	@if $size {
		font-size: $size;
	}

	@if $color {
		color: $color;
	}

	@if $weight {
		font-weight: $weight;
	}

	@if $lh {
		line-height: $lh;
	}
}

// Montserrat
@mixin font-montserrat($size: false, $color: false, $weight: false, $lh: false) {
	font-family: Montserrat, sans-serif;

	@if $size {
		font-size: $size;
	}

	@if $color {
		color: $color;
	}

	@if $weight {
		font-weight: $weight;
	}

	@if $lh {
		line-height: $lh;
	}
}
