@import "src/theme/base/font";
@import "../variables";

.empty-state-container {
	text-align: center;
	object-fit: contain;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.empty-state-icon {
		height: 3.5rem;
		width: 3.5rem;
	}

	h6 {
		margin-top: 1rem;
		margin-bottom: 0.25rem;

		@include font-montserrat($weight: 600, $color: $gray-shade-10, $lh: 21.6px);
	}

	p {
		@include font-roboto($weight: 400, $color: $gray-shade-6, $lh: 21px);

		margin: 0;
		font-size: 14px;
	}

	button {
		width: fit-content;
		margin-top: 8px;
	}
}
