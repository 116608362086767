@import "./../base/font";
@import "./../variables";

.filter-sidebar-container {
	position: static !important;
	height: 100% !important;

	.mat-drawer-backdrop {
		position: fixed;
		width: 100vw;
	}

	.filter-sidenav-left:not(.disable) {
		z-index: 5;
		width: 20rem;
		margin-top: 2px;
		transform: none !important;

		.filter-sidebar-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 0.25rem;

			.mat-icon {
				svg {
					path {
						stroke: $gray-shade-8;
					}
				}
			}

			h4 {
				margin: 0;
			}
		}

		p {
			margin-bottom: 0.25rem;
			margin-top: 0.5rem;
		}

		.primary-caption {
			margin-top: 0.5rem !important;
			cursor: pointer;
		}

		.mat-drawer-inner-container {
			padding: 1.5rem !important;
		}

		.filterbar-footer {
			display: flex;
			gap: 1rem;
			background: #fff;
			z-index: 10000;
			position: absolute;
			width: 86%;
			bottom: 1.5rem;

			button {
				height: 2.75rem !important;
			}
		}

		.subtitle {
			@include font-roboto($weight: 500, $size: 1rem, $lh: 1.5rem, $color: $gray-shade-10);
		}
	}

	.filter-sidenav:not(.disable) {
		z-index: 5;
		width: 23rem;
		right: 0;
		transform: none !important;

		.filter-sidebar-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 0.25rem;

			.mat-icon {
				svg {
					path {
						stroke: $gray-shade-8;
					}
				}
			}

			h4 {
				margin: 0;
			}
		}

		p {
			margin-bottom: 0.25rem;
			margin-top: 0.5rem;
		}

		.primary-caption {
			margin-top: 0.5rem !important;
			cursor: pointer;
		}

		.mat-drawer-inner-container {
			padding: 1.5rem !important;
		}

		.filterbar-footer {
			bottom: 1.5rem;
			width: 88%;
			display: flex;
			gap: 1rem;
			background: #fff;
			z-index: 10000;
			position: absolute;

			button {
				height: 2.75rem !important;
			}
		}

		.subtitle {
			@include font-roboto($weight: 500, $size: 1rem, $lh: 1.5rem, $color: $gray-shade-10);
		}
	}
}

.program-category {
	.categories {
		display: flex;
		flex-direction: column;
		gap: 0.75rem;
	}
}

.filter-options-container {
	.track-list-container {
		margin-top: 1.5rem;
	}

	.checkbox-container {
		margin-bottom: 0.75rem;

		.track-list-checkbox {
			display: flex;
		}
	}

	.show {
		padding-top: 0.25rem;
	}
}
