// primary colors
$primary-1: #2196f3;
$primary-2: #fff;
$primary-3: #000;
$primary-4: #2c73d6;

// secondary shades
$secondary-shade-1: #1a78c2;
$secondary-shade-2: #67bcff;
$secondary-shade-3: #a7d8ff;
$secondary-shade-4: #e4f2fe;
$secondary-shade-5: #f0f8ff;
$secondary-shade-6: #f7fbff;
$secondary-shade-7: #fcfeff;

// tertiary
$tertiary-1: #062455;

// gray shades
$gray-shade-1: #fcfcfd;
$gray-shade-2: #f9fafb;
$gray-shade-3: #f2f4f7;
$gray-shade-4: #eaecf0;
$gray-shade-5: #d0d5dd;
$gray-shade-6: #98a2b3;
$gray-shade-7: #667085;
$gray-shade-8: #475467;
$gray-shade-9: #344054;
$gray-shade-10: #1d2939;
$gray-shade-11: #101828;

// alert colors
// error
$error-1: #eb0000;
$error-2: #fffbfa;
$error-3: #b42318;
$error-4: #f20c00;

// success
$success-1: #04bfa4;
$success-2: #f6fef9;
$success-3: #007d6b;

// warning
$warning-1: #f7d64d;
$warning-2: #fffcf5;
$warning-3: #dc6803;

// score chip
$average-bg: #fffaeb;
$average-color: #e86f05;
$good-bg: #f6fef9;
$good-color: #007c0d;
$poor-bg: #fffbfa;
$poor-color: #c40a00;
$no-score-bg: #f9fafb;
$no-score-color: #98a2b3;
