@import "./shadows";
@import "./../variables";
@import "./../base/font";

.mat-tooltip-panel {
	min-height: 1.813rem;
	margin: 0;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	padding: 0;
	pointer-events: none;

	mat-tooltip-component {
		display: flex;
		flex-direction: column;
		align-items: flex-end;

		.mat-tooltip {
			margin: 0 !important;
			padding: 0.25rem 0.75rem;
			box-shadow: $shadow-lg;

			@include font-roboto($weight: 400, $lh: 150%, $size: 0.875rem, $color: $gray-shade-10);

			background-color: $primary-2;
			overflow: visible;
		}

		.tooltip {
			@include font-roboto($color : $gray-shade-10, $weight : 500, $lh : 120%, $size : 13px);
		}
	}

	&.mat-tooltip-panel-above {
		.mat-tooltip {
			&::before {
				background-image: url("../../assets/icons/tooltip-arrow-down.svg");
				left: calc(50% - 0.75rem / 2);
				bottom: calc(-0.156rem - 0.313rem);
				position: absolute;
				content: "";
				display: inline-block;
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
				width: 0.75rem;
				height: 0.75rem;
				transform: scale(0);
				animation: mat-tooltip-show 200ms cubic-bezier(0, 0, 0.2, 1) forwards;
			}
		}
	}

	&.mat-tooltip-panel-below {
		.mat-tooltip {
			&::before {
				background-image: url("../../assets/icons/tooltip-arrow-up.svg");
				left: calc(50% - 0.75rem / 2);
				top: calc(-0.156rem - 0.313rem);
				position: absolute;
				content: "";
				display: inline-block;
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
				width: 0.75rem;
				height: 0.75rem;
				transform: scale(0);
				animation: mat-tooltip-show 200ms cubic-bezier(0, 0, 0.2, 1) forwards;
			}
		}
	}

	&.mat-tooltip-panel-left {
		.mat-tooltip {
			&::before {
				background-image: url("../../assets/icons/tooltip-arrow-right.svg");
				top: calc(50% - 0.75rem / 2);
				right: calc(-0.156rem - 0.313rem);
				position: absolute;
				content: "";
				display: inline-block;
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
				width: 0.75rem;
				height: 0.75rem;
				transform: scale(0);
				animation: mat-tooltip-show 200ms cubic-bezier(0, 0, 0.2, 1) forwards;
			}
		}
	}

	&.mat-tooltip-panel-right {
		.mat-tooltip {
			&::before {
				background-image: url("../../assets/icons/tooltip-arrow-left.svg");
				top: calc(50% - 0.75rem / 2);
				left: calc(-0.156rem - 0.313rem);
				position: absolute;
				content: "";
				display: inline-block;
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
				width: 0.75rem;
				height: 0.75rem;
				transform: scale(0);
				animation: mat-tooltip-show 200ms cubic-bezier(0, 0, 0.2, 1) forwards;
			}
		}
	}
}
