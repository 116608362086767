@import "../variables";

.mat-radio-checked {
	.mat-radio-outer-circle {
		border-color: $primary-1 !important;
	}

	.mat-radio-inner-circle {
		background-color: $primary-1 !important;
	}
}

.mat-radio-button {
	.mat-radio-container {
		.mat-radio-outer-circle {
			border-width: 1px !important;
			border-color: $gray-shade-5;
		}
	}

	.mat-radio-label-content {
		padding-left: 0.25rem !important;
		color: $gray-shade-10 !important;
	}
}

.mat-radio-disabled {
	.mat-radio-label {
		&-content {
			color: #d0d5dd !important;
		}
	}

	.mat-radio-container {
		.mat-radio-outer-circle {
			border: 1px solid $gray-shade-5 !important;
		}

		.mat-radio-inner-circle {
			background-color: $gray-shade-5 !important;
		}
	}
}

.calendar {
	.calendar-table {
		.records-container {
			.attendance {
				.mat-radio-group {
					display: flex;
					gap: 12%;

					.mat-radio-container {
						// transform: scale(0.85);
						width: .75rem !important;
						height: .75rem !important;

						.mat-radio-outer-circle,
						.mat-radio-inner-circle {
							width: 100% !important;
							height: 100% !important;
						}

						.mat-radio-ripple {
							display: none;
						}
					}

					.mat-radio-label-content {
						font-size: .65rem !important;
					}
				}
			}

			.yes {
				.mat-radio-group {
					.yes {
						.mat-radio-container {
							.mat-radio-outer-circle {
								border-color: $success-3 !important;
							}

							.mat-radio-inner-circle {
								background-color: $success-3 !important;
							}
						}
					}
				}
			}

			.no {
				.mat-radio-group {
					.no {
						.mat-radio-container {
							.mat-radio-outer-circle {
								border-color: $error-3 !important;
							}

							.mat-radio-inner-circle {
								background-color: $error-3 !important;
							}
						}
					}
				}
			}
		}
	}
}