@import "../variables";
@import "./shadows";
@import "../base/font";
@import "../base/mixins";

.mat-form-field {
	.mat-form-field-infix {
		.mat-select-arrow-wrapper {
			transform: unset !important;

			.mat-select-arrow {
				border-left: 12px solid transparent !important;
				border-right: none !important;
				border-top: 8px solid transparent !important;
				border-image-source: url("../../assets/icons/dropdown-arrow.svg") !important;
				border-image-repeat: stretch !important;
			}
		}

		.mat-select[aria-controls] {
			.mat-select-trigger {
				.mat-select-arrow-wrapper {
					transform: unset !important;

					.mat-select-arrow {
						border-left: 12px solid transparent !important;
						border-right: none !important;
						border-top: 8px solid transparent !important;
						border-image-source: url("../../assets/icons/dropdown-collapse.svg") !important;
						border-image-repeat: stretch !important;
					}
				}
			}
		}
	}
}

.formfield-with-chip {
	.mat-form-field-flex {
		height: fit-content !important;
	}
}

.mat-option {
	.mat-option-text {
		display: flex;
		align-items: center;
		gap: 0.5rem;

		.mat-icon {
			position: absolute;
			right: 1rem;
			padding: 0.25rem;
			margin-right: 0;
		}
	}
}

.mat-select-panel-wrap {
	// position: absolute;
	margin-top: 2rem;
	top: 50px;
	left: 0.25rem;
	width: 100%;

	.mat-select-panel {
		min-width: calc(100% + 24px) !important;
		margin: 0 !important;
		overflow: overlay !important;

		&[aria-multiselectable="true"] {
			transform: translateX(24px) !important;
		}

		.mat-option-text {
			color: #1d2939;

			.tick-icon {
				width: 13.33px;
				height: 9.17px;
				position: absolute;
				right: 0;
				bottom: 35%;
			}
		}
	}

	.demo {
		min-width: calc(100% + 36px) !important;
	}
}

.mat-pseudo-checkbox {
	border: 1px solid $gray-shade-5 !important;

	&::after {
		color: $primary-1;
		left: 0.125rem !important;
		top: 0.1875rem !important;
	}

	&-checked,
	&-indeterminate {
		background: $secondary-shade-4 !important;
		border: 1px solid $primary-1 !important;
	}
}

.mat-checkbox {
	&-ripple {
		visibility: hidden;
	}
}

.dropdown-option-text {
	width: 100%;
}

.mat-menu-panel {
	.mat-menu-content {
		padding: 0 !important;

		.options-container {
			.option {
				.mat-menu-item {
					.mat-icon {
						margin-right: 0 !important;
					}

					.mat-menu-submenu-icon {
						display: none !important;
					}
				}
			}
		}
	}
}

.grid-container {
	.mat-table {
		.mat-row {
			.mat-column-mentor,
			.mat-column-duration {
				.mat-form-field {
					.mat-form-field-wrapper {
						padding-bottom: 0 !important;

						.mat-select {
							.mat-select-trigger {
								.mat-select-value {
									.mat-select-placeholder {
										padding-bottom: 1rem !important;
									}
								}
							}
						}
					}
				}

				.mat-error {
					margin-bottom: 1rem !important;
				}
			}
		}

		.mat-header-row {
			.mat-header-cell,
			.mat-column-checkbox {
				.mat-sort-header-container {
					.mat-sort-header-content {
						display: flex;
						gap: 0.25rem;

						.mat-icon {
							transform: scale(0.55);
							position: relative;
							top: 4.75px;
						}

						.mat-icon :hover {
							cursor: pointer;
						}
					}
				}
			}
		}
	}
}
