@import "../variables";
@import "./shadows";
@import "../base/font";
@import "../base/mixins";

.mat-select-panel {
	.status-options {
		.mat-icon {
			position: unset;
			display: flex !important;
			width: 0.5rem !important;
			height: 0.5rem !important;
		}
	}
}

.status-icon {
	position: unset !important;
	display: flex;
	padding: 0 !important;
	width: 0.5rem !important;
	height: 0.5rem !important;

	&.training-yellow {
		svg {
			circle {
				fill: #f7d64d !important;
			}
		}
	}

	&.exit-red {
		svg {
			circle {
				fill: #eb0000 !important;
			}
		}
	}

	&.confirmed-green {
		svg {
			circle {
				fill: #007d6b !important;
			}
		}
	}

	&.resigned-red {
		svg {
			circle {
				fill: #eb0000 !important;
			}
		}
	}
}

.mat-focused {
	.mat-form-field-flex {
		border: 1px solid $primary-1 !important;
		box-shadow: $shadow-focused;

		.mat-form-field-prefix {
			color: $gray-shade-10;

			.mat-icon {
				svg {
					path {
						stroke: $gray-shade-6 !important;
						stroke-width: 0.104rem;
					}
				}
			}
		}

		.mat-form-field-infix {
			::placeholder {
				@include font-roboto($weight: 400, $size: 1rem, $color: $gray-shade-6);
			}
		}
	}
}

.search-track-input {
	.mat-form-field-wrapper {
		.mat-form-field-flex {
			margin: 0 !important;
		}
	}
}

.social-login {
	.mat-form-field-wrapper {
		.mat-form-field-flex {
			min-height: 2.75rem !important;
		}
	}
}

.invalid-input-box {
	.mat-form-field-wrapper .mat-form-field-flex {
		border: 0.063rem solid $error-1 !important;
		box-shadow: $shadow-xs;
	}
}

.mat-form-field {
	width: 100%;

	.mat-form-field-wrapper {
		padding-bottom: 12px !important;

		.mat-form-field-flex {
			min-height: 2.5rem;
			background-color: $primary-2;
			border: 0.063rem solid $gray-shade-5;
			border-radius: 0.5rem;
			padding: 0.5rem 0.75rem !important;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0.25rem 0;

			&:hover {
				border: 0.063rem solid $gray-shade-6;
			}

			.mat-form-field-prefix {
				padding: 0 0.5rem 0 0 !important;
				color: $gray-shade-6;

				.phone {
					.country-code {
						padding-right: 0.5rem;
						border-right: 1px solid $gray-shade-5;
					}
				}

				.mat-icon {
					@include typography($size: 100%);

					width: 1.25rem;
					height: 1.25rem;

					svg {
						path {
							stroke: $gray-shade-6;
							stroke-width: 0.104rem;
						}
					}
				}
			}

			.mat-form-field-suffix {
				.mat-icon {
					width: 1.25rem;
					height: 1.25rem;
				}

				.dropdown-icon {
					display: flex;
					padding: 0.25rem;

					&:hover {
						cursor: pointer;
					}
				}
			}

			.mat-form-field-infix {
				border-top: 0;
				padding: 0 !important;

				.mat-date-range-input-separator {
					color: $gray-shade-7 !important;
				}

				.mat-input-element {
					@include font-roboto($weight: 400, $size: 1rem, $color: $gray-shade-10);

					caret-color: $gray-shade-5;
					resize: none;

					&[type="password"] {
						font-size: 1.5rem !important;
					}
				}

				::placeholder {
					@include font-roboto($weight: 400, $size: 1rem, $color: $gray-shade-6);
				}
			}
		}

		&.filled {
			.mat-form-field-prefix {
				color: $gray-shade-10;

				.mat-icon {
					svg {
						path {
							stroke: $gray-shade-10;
							stroke-width: 0.104rem;
						}
					}
				}
			}
		}

		&.mat-form-field-disabled {
			.mat-form-field-flex {
				border: 0.063rem solid $gray-shade-5;
				background-color: $gray-shade-2;

				.mat-form-field-prefix,
				.mat-form-field-suffix {
					color: $gray-shade-5;

					.mat-icon {
						svg {
							path {
								stroke: $gray-shade-5;
							}
						}
					}
				}

				.mat-form-field-infix {

					.mat-input-element,
					::placeholder {
						@include typography($color: $gray-shade-5);
					}
				}
			}
		}

		.mat-form-field-subscript-wrapper {
			margin: 0 !important;
			padding: 0 !important;
			position: unset;

			.mat-error {
				@include font-roboto($weight: 400, $size: 0.875rem, $color: $error-1, $lh: 150%);
			}
		}

		.mat-form-field-underline {
			display: none;
		}
	}

	&.auto-complete-list-validation {
		.mat-form-field-wrapper {
			.mat-form-field-flex {
				border: 0.063rem solid $error-1 !important;
				box-shadow: $shadow-xs;
			}
		}
	}

	&.mat-form-field-invalid,
	&.mat-focused.mat-form-field-invalid {
		.mat-form-field-wrapper {
			.mat-form-field-flex {
				border: 0.063rem solid $error-1;
				box-shadow: $shadow-xs;
			}
		}
	}
}

label {
	@include font-montserrat($weight: 600, $size: 0.875rem, $lh: 120%, $color: $gray-shade-10);
}

.grid-container {
	.mat-table {
		.mat-row {
			.selected {
				.mat-form-field {
					.mat-form-field-wrapper {
						.mat-form-field-flex {
							border: 0.063rem solid $gray-shade-5 !important;
						}
					}
				}
			}

			.error {
				.mat-form-field {
					.mat-form-field-wrapper {
						.mat-form-field-flex {
							border: 0.063rem solid $error-1;
						}
					}
				}
			}

			.mat-column-mentor,
			.mat-column-duration {
				.mat-form-field {
					width: 16.875rem;

					.mat-input-element {
						height: 24px;
					}
				}
			}
		}

		.mat-header-row {

			.mat-column-pipcandidates,
			.mat-column-checkbox {
				.mat-sort-header-arrow {
					display: none;
				}
			}
		}
	}
}

.calendar {
	.calendar-header {
		.date-picker {
			.mat-form-field {
				.mat-form-field-wrapper {
					.mat-form-field-flex {
						height: 1rem !important;

						.mat-form-field-infix {
							display: flex !important;
						}
					}
				}
			}
		}
	}
}