@import "../variables";

.mat-checkbox {
	&:hover {
		.mat-checkbox-frame {
			border-color: $primary-1 !important;
			background-color: $secondary-shade-4 !important;
		}
	}

	&:focus {
		.mat-checkbox-frame {
			border-color: $primary-1 !important;
		}
	}

	&:disabled {
		color: $gray-shade-5 !important;

		.mat-checkbox-frame {
			border-color: $gray-shade-5 !important;
		}
	}

	.mat-checkbox-layout {
		.mat-checkbox-inner-container {
			.mat-checkbox-frame {
				border: 1px solid $gray-shade-5;
				border-radius: 0.25rem !important;
			}
		}

		.mat-checkbox-label {
			@include font-montserrat($size: 14px, $color: $gray-shade-10, $weight: 600, $lh: 120%);
		}
	}

	&-checked {
		.mat-checkbox-checkmark-path {
			stroke: $primary-1 !important;
		}

		&.mat-accent {
			.mat-checkbox-background {
				background-color: $secondary-shade-4 !important;
				border: 1px solid $primary-1 !important;
				color: $primary-1 !important;
				border-radius: 0.25rem !important;
			}
		}
	}
}

.mat-pseudo-checkbox {
	border-radius: 0.25rem !important;
}

.trainings-checkbox {
	.mat-checkbox-layout {
		.mat-checkbox-label {
			font-family: Roboto, Helvetica, Arial, sans-serif !important;
			font-size: 1rem !important;
			font-weight: 400 !important;
		}
	}
}
