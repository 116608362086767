@import "../variables";
@import "./shadows";
@import "../base/font";

.toolbar-container {
	.mat-icon {
		display: flex;
	}
}

.mat-flat-button {
	@include font-montserrat($weight: 600);

	border-radius: 0.5rem !important;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;

	&.signin-page-btn {
		padding: 0.25rem 0 !important;
	}

	&.sm {
		@include typography($size: 0.875rem, $weight: 600, $lh: 130%);

		min-height: 2.5rem;
		padding: 0.5rem 1rem !important;
	}

	&.lg {
		@include typography($size: 1rem, $weight: 600, $lh: 120%);

		padding: 0.75rem 1.25rem !important;
		height: 2.75rem;
	}

	&.mat-primary {
		color: $primary-2 !important;
		background-color: $primary-1;

		&:hover {
			background-color: $secondary-shade-1;
		}

		&:disabled {
			background-color: $secondary-shade-3;
		}

		&:enabled:active {
			background-color: $primary-1;
			box-shadow: $shadow-focused;
		}
	}

	&.tertiary {
		display: flex;
		max-height: 1.75rem;
		width: fit-content;
		color: $primary-1 !important;
		background-color: $primary-2;
		padding: 4px 12px !important;

		&-small {
			height: 24px !important;
		}

		.mat-icon {
			svg {
				path {
					stroke: $primary-1 !important;
				}
			}
		}

		&:hover {
			background-color: $secondary-shade-5;
		}

		&:disabled {
			background-color: $primary-2;
			color: $gray-shade-5 !important;
		}

		&:enabled:active {
			color: $primary-1 !important;
			background-color: $primary-2;
		}
	}

	&.social-login {
		@include typography($size: 0.875rem, $weight: 600, $lh: 130%);

		padding: 0.5rem 1rem !important;
		outline: 1px solid $gray-shade-5 !important;
		color: $gray-shade-9 !important;
		background-color: $primary-2;
		box-shadow: $shadow-xs;
		height: 2.75rem;

		&:hover {
			background-color: $gray-shade-2;
		}

		&:enabled:active {
			outline: 1px solid $primary-1 !important;
			background-color: $primary-2;
			box-shadow: $shadow-focused;
		}

		.mat-icon {
			width: 1.5rem;
			height: 1.5rem;

			svg {
				path {
					stroke: none !important;
				}
			}
		}
	}

	&.secondary {
		outline: 1px solid $primary-1 !important;
		color: $primary-1 !important;
		background-color: $primary-2;

		.mat-icon {
			svg {
				path {
					stroke: $primary-1 !important;
				}
			}
		}

		&:disabled {
			background-color: $primary-2;
			color: $gray-shade-5 !important;
			outline-color: $gray-shade-5 !important;
			pointer-events: none;

			.mat-icon {
				svg {
					path {
						stroke: $gray-shade-5 !important;
					}
				}
			}
		}

		&:hover,
		&:focus,
		&:enabled:focus {
			background-color: $secondary-shade-5;
		}

		&:enabled:active {
			background-color: $primary-2 !important;
			box-shadow: $shadow-focused !important;
		}
	}

	&.error-red {
		outline: 1px solid $error-1 !important;
		color: $error-1 !important;

		&:hover {
			background-color: $error-2;
		}

		&:focus {
			box-shadow: $shadow-red;
		}
	}

	&.success-green {
		outline: 1px solid $success-3 !important;
		color: $success-3 !important;

		&:hover {
			background-color: $success-2;
		}

		&:focus {
			box-shadow: $shadow-green;
		}
	}

	.mat-button-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 0.5rem;

		.mat-icon:not(.user-type-icon) {
			max-width: 1.25rem;
			max-height: 1.25rem;

			svg {
				path {
					stroke: white;
				}
			}
		}
	}

	.signin-btn {
		.mat-button-wrapper {
			.mat-icon {
				max-width: 1.5rem;
				max-height: 1.5rem;
			}
		}
	}
}

.mat-button-focus-overlay {
	opacity: 0 !important;
}

.sign-in-btn {
	height: 2.75rem !important;
}

.clear-all-btn {
	cursor: pointer;
	font-weight: 600;
	margin-left: 0.5rem;
}

.action-btn {
	cursor: pointer;
}

.comment-active-state {
	background-color: $secondary-shade-4 !important;
}

.choose-role-container {
	.roles-container {
		.role-icon {
			.mat-icon {
				min-width: 24px;

				svg {
					path {
						stroke: #667085 !important;
					}
				}
			}
		}
	}
}

.feedback-options-wrapper {
	.parameter-field {
		.mat-icon-button {
			width: 40px !important;
		}
	}
}

.trainer-header {
	.side-menu {
		.switch-role {
			.mat-icon {
				svg {
					path {
						stroke: $primary-1 !important;
					}
				}
			}
		}
	}
}

.page-body {
	.download-button {
		.mat-flat-button {
			.mat-button-wrapper {
				.mat-icon {
					svg {
						path {
							stroke: $primary-1 !important;
						}
					}
				}
			}
		}
	}
}

.programs-wrapper {
	.page-body {
		.search-menu {
			.mat-flat-button {
				.mat-button-wrapper {
					.mat-icon {
						svg {
							path {
								stroke: $primary-1 !important;
							}
						}
					}
				}
			}
		}
	}
}

.admin-trainer-wrapper {
	.page-body {
		.search-menu {
			.mat-flat-button {
				.mat-button-wrapper {
					.mat-icon {
						svg {
							path {
								stroke: $primary-1 !important;
							}
						}
					}
				}
			}
		}
	}
}

.batches-wrapper {
	.page-body {
		.search-menu {
			.mat-flat-button {
				.mat-button-wrapper {
					.mat-icon {
						svg {
							path {
								stroke: $primary-1 !important;
							}
						}
					}
				}
			}
		}
	}
}

.candidates-wrapper {
	.page-body {
		.search-menu {
			.mat-flat-button {
				.mat-button-wrapper {
					.mat-icon {
						svg {
							path {
								stroke: $primary-1 !important;
							}
						}
					}
				}
			}
		}
	}
}

.mat-drawer-container {
	.mat-drawer {
		.mat-drawer-inner-container {
			.notification-container {
				.notification-button {
					.mat-icon {
						svg {
							path {
								stroke: #667085 !important;
							}
						}
					}

					.mat-button-wrapper {
						gap: 0 !important;
					}
				}
			}
		}
	}
}

.auth-container {
	.mat-drawer-container {
		.mat-drawer {
			.mat-drawer-inner-container {
				.user {
					.profile {
						.profile-details {
							.role-container {
								.switch-role {
									.mat-flat-button {
										.mat-icon {
											svg {
												path {
													stroke: $primary-1 !important;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
