@import "../variables";
@import "../base/font";

.mat-chip.mat-standard-chip {
	@include font-roboto;

	display: flex;
	padding: 0.125rem 0.5rem;
	background-color: $gray-shade-4;
	color: $gray-shade-10;
	text-align: center;
	border-radius: 16px;
	overflow: hidden;
	align-items: center;
	justify-content: center;
	min-height: 1.5rem;

	.chip-content {
		color: $gray-shade-10;
		font-weight: 400 !important;
	}

	.cancel-icon {
		width: 0.5rem !important;
		opacity: 1;
		margin-left: 0.25rem;

		&:hover {
			cursor: pointer;
		}
	}

	.mat-chip-remove.mat-icon {
		display: flex;
		width: 12px !important;
		height: 12px;
	}
}

.small {
	padding: 2px 8px 2px 10px !important;
	font-size: 14px !important;
	line-height: 21px !important;
}

.large {
	padding: 4px 10px 4px 12px !important;
	font-size: 1rem !important;
	line-height: 24px !important;
}

.primary-chip {
	background-color: $primary-1 !important;
	color: $primary-2 !important;
	margin-left: 0.5rem;
	padding: 0 0.625rem !important;
	min-height: 1.3125rem !important;
}

.gray-chip {
	background-color: $gray-shade-3 !important;
	color: $gray-shade-7 !important;
	padding: 0 0.625rem !important;
	min-height: 1.3125rem !important;
}

.external-trainer-indicator {
	padding: 0.125rem 0.5rem !important;
	background-color: $secondary-shade-4 !important;
	color: $secondary-shade-1 !important;
	font-weight: 400;
	font-size: 0.8125rem;
	border-radius: 1.25rem !important;
	min-height: 1.25rem !important;
}

.pip-indicator {
	@include font-roboto($size: 0.8125rem, $weight: 500, $color: $error-1 !important, $lh: unset);

	padding: 0.125rem 0.5rem !important;
	background-color: $error-2 !important;
	border: 1px solid $error-1;
	border-radius: 1rem !important;
}

.ellipsis-chip {
	border-radius: 50% !important;
	width: fit-content !important;
	padding: 0.4rem !important;
	border: 1px solid $gray-shade-4 !important;
	background-color: $secondary-shade-5 !important;
	color: $gray-shade-8 !important;
	cursor: pointer;
}


.training-names {
	background-color: $secondary-shade-5  !important;
	color:$primary-1 !important;
}

.mentor-trainings-chip {
	width: 32px !important;
	height: 32px;
}

.score-chip.mat-chip {
	pointer-events: none;
	padding: 0 0.5rem;
	border: 0.063rem solid;
	border-radius: 1rem;
	width: fit-content;
	visibility: visible !important;
	min-width: 2.25rem;
	display: flex;
	justify-content: center;
	align-items: center;

	.mat-chip-ripple {
		display: none;
	}

	&.average {
		background: $average-bg;
		border-color: $average-color;
		color: $average-color;
	}

	&.good {
		background: $good-bg;
		border-color: $good-color;
		color: $good-color;
	}

	&.poor {
		background: $poor-bg;
		border-color: $poor-color;
		color: $poor-color;
	}

	&.no-score {
		background: $no-score-bg;
		border-color: $no-score-color;
		color: $no-score-color;
	}
}

.training-chip {
	@include font-roboto($weight: 400, $color: $gray-shade-7);

	background: $primary-2;
	border: 1px solid $gray-shade-7;

	&.selected-chip {
		background: $success-3 !important;
		color: $primary-2 !important;
	}
}

.candidate-chip {
	@include font-roboto($weight: 400, $color: $gray-shade-7);

	background: $primary-2;
	border: 1px solid $gray-shade-7;

	&.selected-chip {
		background: $success-3 !important;
		color: $primary-2 !important;
	}
}

.mat-chip-list-wrapper {
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	max-height: 9rem;
	overflow-y: scroll;

	.mat-standard-chip {
		margin: 0.25rem !important;
		padding: 0.125rem 0.5rem;
	}

	.candidates-chip-list {
		margin: 0.125rem 0.25rem !important;
		padding: 0.125rem 0.5rem;
	}
}

.selection-indicator-chip {
	background-color: $gray-shade-8 !important;
	color: $primary-2 !important;
	padding: 0 0.625rem !important;
	min-height: 1.3125rem !important;
}

.no-action-chip {
	&.mat-standard-chip {
		&::after {
			background-color: unset !important;
		}
	}
}

.applied-filters-list {
	.mat-chip-list-wrapper {
		margin-top: 0.5rem !important;
	}
}

.mat-standard-chip.mat-chip-with-trailing-icon {
	padding-left: 8px !important;
}

.selected-track-chip {
	@include font-roboto($size: 0.875rem, $color: $gray-shade-10, $weight: 400, $lh: 150%);
}