@import "./shadows";
@import "./../variables";
@import "./../common-style/typography";
@import "./../base/font";

.mat-nav-list {
	padding: 0 1rem;
	padding-top: 0 !important;

	.mat-list-item {
		height: 2.5rem !important;
		border-radius: 0.375rem;
		margin-bottom: 0.7rem;
		background: none;

		&-content {
			padding: 0.5rem 0.75rem;

			.mat-ripple {
				display: none !important;
			}

			.mat-icon {
				width: 1.25rem !important;
				height: 1.25rem !important;
				padding: 0 !important;
				border-radius: 0% !important;

				@include typography($size: 100% !important);

				svg {
					path {
						width: 1rem !important;
						height: 1rem !important;
					}
				}
			}

			.mat-list-text {
				padding-left: 8px !important;

				.mat-line {
					@include font-roboto($size: 1rem, $weight: 400, $lh: 150%, $color: $gray-shade-7);
				}
			}
		}

		&.active {
			background-color: $secondary-shade-6;

			.mat-list-item-content {
				.mat-list-text {
					.mat-line {
						color: $primary-1;
						font-weight: 500;
					}
				}
			}
		}

		&:hover {
			background-color: $gray-shade-2;
		}
	}
}

.selected {
	z-index: 1 !important;
}

.mat-drawer-container {
	height: 100%;

	// .mat-drawer-content {
	// 	z-index: 2;
	// }

	.mat-drawer.mat-drawer-side {
		width: 20rem;
		border: none;
		box-shadow: $shadow-lg;

		.mat-drawer-inner-container {
			display: flex;
			flex-direction: column;

			.company {
				.logo {
					padding: 1.5rem 1.75rem 2rem;

					.mat-icon {
						width: 8.875rem;
						height: 2.213rem;
					}
				}

				.mat-divider {
					margin: 0 1rem;
				}
			}

			.nav {
				padding-top: 2rem;
			}

			.spacer {
				flex: 1 1 auto;
			}

			.user {
				.profile {
					display: flex;
					justify-content: flex-start;
					padding: 0.813rem 2rem;

					&-image {
						width: 2.5rem;
						height: 2.5rem;
						background-color: $gray-shade-6; // temp
						border-radius: 50%;
					}

					&-details {
						padding-left: 1rem;
						display: flex;
						flex-direction: column;
						justify-content: center;

						.name {
							@include font-montserrat($size: 1.125rem, $lh: 120%, $weight: 600, $color: $gray-shade-10);
						}

						.role {
							@include font-roboto($size: 0.875rem, $lh: 150%, $weight: 400, $color: $gray-shade-7);
						}
					}
				}
			}
		}
	}
}
