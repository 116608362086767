// Media Queries
$mobile-min: 320;
$mobile-max: 767;
$tablet-min: 768;
$tablet-max: 1023;

@mixin mobile {
	@media only screen and (max-width: ($mobile-max * 1px)) {
		@content;
	}
}

@mixin tablet {
	@media only screen and (min-width: ($tablet-min * 1px)) and (max-width: ($tablet-max * 1px)) {
		@content;
	}
}

// set font styles
@mixin typography(
	$size: false,
	$color: false,
	$weight: false,
	$lh: false,
	$ls: false
) {
	@if $size {
		font-size: $size;
	}

	@if $color {
		color: $color;
	}

	@if $weight {
		font-weight: $weight;
	}

	@if $lh {
		line-height: $lh;
	}

	@if $ls {
		letter-spacing: $ls * 1px;
	}
}

// flex
@mixin flexbox(
	$justify-content: false,
	$align-items: false,
	$direction: false,
	$flex: false,
	$gap: false,
	$flex-wrap: false
) {
	display: flex;

	@if $justify-content {
		justify-content: $justify-content;
	}

	@if $align-items {
		align-items: $align-items;
	}

	@if $direction {
		flex-direction: $direction;
	}

	@if $flex {
		flex: $flex;
	}

	@if $gap {
		gap: $gap * 1rem;
	}

	@if $flex-wrap {
		flex-wrap: wrap;
	}
}

// add/remove margins
@mixin push-ends($spacing) {
	margin: {
		top: $spacing;
		bottom: $spacing;
	}
}

@mixin push-sides($spacing) {
	margin: {
		left: $spacing;
		right: $spacing;
	}
}

@mixin push-auto {
	margin: {
		left: auto;
		right: auto;
	}
}

@mixin offset-sides($spacing) {
	margin: {
		left: -$spacing;
		right: -$spacing;
	}
}

@mixin flush-ends {
	margin: {
		top: 0;
		bottom: 0;
	}
}

@mixin flush-sides {
	margin: {
		left: 0;
		right: 0;
	}
}

@mixin flush-all {
	margin: {
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	}
}

// add/remove paddings
@mixin soft-ends($spacing) {
	padding: {
		top: $spacing;
		bottom: $spacing;
	}
}

@mixin soft-sides($spacing) {
	padding: {
		left: $spacing;
		right: $spacing;
	}
}

@mixin hard-ends {
	padding: {
		top: 0;
		bottom: 0;
	}
}

@mixin hard-sides {
	padding: {
		left: 0;
		right: 0;
	}
}

@mixin hard-all {
	padding: {
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	}
}
