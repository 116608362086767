@import "../variables";

* {
	box-sizing: border-box;
}

html,
body {
	height: 100%;
}

body {
	margin: 0;
}

.page-body {
	min-height: calc(100vh - 70px);
	overflow-y: auto;
	padding: 24px;
	background-color: $secondary-shade-7;
	position: relative;
	display: flex;
	flex-direction: column;
	height: 100%;

	.search-menu {
		position: absolute;
		width: fit-content;
		top: 16px;
		height: 58px !important;
		right: 24px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 0.5rem;

		button {
			width: fit-content;
		}

		.mat-form-field {
			width: fit-content;

			&-wrapper {
				padding: 0 !important;

				.mat-form-field-flex {
					margin: 4px 0 0 !important;
				}
			}
		}
	}
}

/* Scrollbar CSS */
::-webkit-scrollbar-track {
	border-radius: 0;
	background-color: #fff;
}

::-webkit-scrollbar {
	width: 16px;
	height: 16px;
	background-color: transparent;
	margin: 8px;
}

::-webkit-scrollbar-thumb {
	background-color: $gray-shade-4;
	border-radius: 8px;
	margin: 4px;
	border: 4px solid transparent;
	background-clip: padding-box;
}

::-webkit-scrollbar-thumb:hover {
	background: $gray-shade-4;
	border: 2px solid $gray-shade-4;
	background-clip: padding-box;
}

.cdk-overlay-pane {
	margin: 0.25rem 0;
}

.notifications-sidebar {
	.mat-drawer-inner-container::-webkit-scrollbar {
		display: none;
	}
}
