@import "../base/mixins";
@import "../base/font";

h1,
h2,
h3,
h4,
h5,
h6 {
	@include font-montserrat($weight: 600, $lh: 120%, $color: $gray-shade-10);
}

h1 {
	@include typography($size: 2rem);
}

h2 {
	@include typography($size: 1.812rem);
}

h3 {
	@include typography($size: 1.625rem);
}

h4 {
	@include typography($size: 1.438rem);
}

h5 {
	@include typography($size: 1.25rem);
}

h6 {
	@include typography($size: 1.125rem);
}

.para-1 {
	@include font-roboto($size: 1.125rem,
	$lh: 160%,
	$weight: 400,
	$color: $gray-shade-7);
}

.para-2 {
	@include font-roboto($size: 1.25rem,
	$lh: 160%,
	$weight: 400,
	$color: $gray-shade-7);
}

.base {
	@include font-roboto($size: 1rem,
	$color: $gray-shade-7,
	$lh: 150%,
	$weight: 400);

	&.md {
		@include typography($weight: 500);
	}
}

.caption-1 {
	@include font-roboto($size: 0.875rem, $lh: 150%, $color: $gray-shade-7);

	// text-align: center;

	&.md {
		@include typography($weight: 500);
	}
}

.caption-2 {
	@include font-roboto($size: 0.812rem, $lh: 160%, $color: $gray-shade-7);

	&.md {
		@include typography($weight: 500, $lh: 120%);
	}
}

.caption-3 {
	@include font-montserrat($size: 0.875rem, $lh: 120%, $color: $gray-shade-8);

	&.md {
		@include typography($weight: 600, $lh: 120%);
	}
}

.caption-4 {
	@include font-roboto($size: 0.875rem, $lh: 150%, $color: $gray-shade-10);

	font-weight: 500 !important;

	&.md {
		@include typography($weight: 500);
	}
}

.caption-5 {
	@include font-montserrat($size: 0.875rem, $lh: 120%, $color: $gray-shade-7);

	font-weight: 400 !important;

	&.md {
		@include typography($weight: 400);
	}
}

.caption-6 {
	@include font-roboto($size: 0.875rem, $lh: 150%, $color: $gray-shade-9);

	font-weight: 500 !important;
}

.caption-7 {
	@include font-roboto($size: 1rem, $lh: 150%, $color: $gray-shade-10);

	&.md {
		@include typography($weight: 500);
	}
}

.caption-8 {
	@include font-roboto($size: 0.875rem, $lh: 150%, $color: $gray-shade-10, $weight: 400);
}

.primary-caption {
	@include font-montserrat($size: 0.875rem, $lh: 120%, $color: $primary-1);

	&.md {
		@include typography($weight: 600, $lh: 120%);
	}
}

.asterisk {
	color: red;
}
