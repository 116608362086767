@import "../base/mixins";
@import "../variables";

.cdk-overlay-container {
	.cdk-overlay-dark-backdrop {
		background: $primary-3 !important;
		opacity: 0.75 !important;
	}
}

.header {
	h3 {
		margin-top: 1rem;
		margin-bottom: 1.5rem;
	}
}

.mat-dialog-container {
	width: 23rem;
	border-radius: 8px !important;
	overflow: hidden !important;

	.mat-dialog-title {
		@include font-montserrat($lh: 120%, $size: 1.25rem, $weight: 600, $color: $gray-shade-10);

		color: $gray-shade-10 !important;
	}

	.mat-dialog-actions {
		display: flex !important;
		flex-wrap: nowrap !important;
	}

	.edit-feedback {
		.mat-horizontal-stepper-wrapper {
			.mat-horizontal-stepper-header-container {
				justify-content: flex-start;
				padding: 0 !important;
				width: 20.375rem !important;
				height: 100% !important;

				.mat-step-header {
					height: min-content !important;
					width: 100% !important;

					.mat-step-icon {
						display: none !important;
					}

					.mat-step-label {
						width: 100% !important;

						.mat-step-text-label {
							width: 100% !important;

							.move-down {
								.sub-label {
									display: none !important;
								}
							}

							.mat-flat-button {
								width: 100%;
								display: flex !important;
								justify-content: flex-start !important;
								padding: 20px 24px !important;
								border-bottom: 2px solid $gray-shade-4;
								border-radius: 0 !important;

								@include font-montserrat($weight: 600, $size: 1rem, $lh: 120%);
							}
						}
					}

					.mat-step-label-selected {
						.mat-step-text-label {
							.mat-flat-button {
								background-color: $secondary-shade-6;
								color: $primary-1;
								border-right: 4px solid $primary-1;
							}
						}
					}
				}

				.mat-stepper-horizontal-line {
					display: none !important;
				}
			}
		}
	}

	.standard-dialog-content {
		width: 21.4rem;
	}
}

.feedback-dialog {
	width: 23rem !important;
}

.assign-trainings-header {
	.mat-icon {
		svg {
			path {
				stroke: #98a2b3 !important;
			}
		}
	}
}

.stepper-dialog {
	.mat-dialog-container {
		transform: none;
		padding: 0;
	}
}
