@import "../variables";
@import "../base/font";
@import "../base/mixins";
@import "../common-style/shadows";

.grid-container {
	padding: 1rem 0 0.5rem;
	height: 100%;
	display: flex;
	flex-direction: column;
	position: relative;
	background-color: $secondary-shade-7;
	gap: 20%;

	.spacer {
		flex: 1 1 auto;

		.no-data {
			height: 100%;
		}
	}

	app-loader {
		position: absolute;
		top: -10%;
		z-index: 5;
	}

	table {
		width: 100%;
		border-radius: 0.5rem;
		border: thin solid $gray-shade-3;
		margin-bottom: 1rem;
		box-shadow: $shadow-sm;
		overflow: hidden;

		.mat-header-row {
			height: 3rem;

			.mat-column-checkbox {
				padding-right: 0;
			}

			.named-avatar-head {
				padding-left: 1.75rem;
			}

			.mat-sort-header-arrow {
				color: $gray-shade-7;
				margin: 0 0 0 4px;
			}
		}

		.mat-head {
			background-color: $gray-shade-2 !important;
			height: 48px;
			padding: 0 24px;
			border-bottom-color: $gray-shade-3;

			@include font-roboto($weight: 500, $lh: 21px, $color: $gray-shade-10, $size: 14px);
		}

		.mat-column-action {
			&.mat-cell {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				height: 3.5rem;

				@include soft-ends(0.25rem);
			}

			&.gap-large {
				gap: 1.5rem;
			}

			&.gap-small {
				gap: 0.5rem;
			}
		}

		.mat-cell {
			color: $gray-shade-10;
			padding: 0 1.5rem;
			max-width: 20rem;
			overflow: hidden;
			border-bottom-color: $gray-shade-3;

			&.clickable {
				color: blue !important;
			}
		}

		.mat-sort-header {
			visibility: initial !important;
		}

		.mat-row {
			height: 3.5rem;
			border-bottom: 1px solid $gray-shade-3;

			.mat-focus-indicator {
				visibility: hidden;
			}

			.mat-chip {
				visibility: visible !important;
			}

			.mat-column-checkbox {
				min-height: 3.5rem;
				padding-right: 0;
				width: 1rem;
			}

			// .named-avatar-cell {
			// 	padding-left: 0;
			// }
		}

		.mat-row:hover {
			cursor: pointer;
			background-color: $gray-shade-1;

			.mat-focus-indicator {
				visibility: initial !important;
			}

			.mat-focus-indicator:hover {
				background-color: $secondary-shade-4;

				svg {
					path {
						stroke: $primary-1 !important;
					}
				}
			}
		}
	}
}

.blue-highlight {
	color: $primary-1 !important;
}

.candidates-grid {
	.mat-tab-header {
		content-visibility: hidden;
	}
}

.header {
	.trainer-body {
		.grid-container {
			.mat-table {
				.mat-row {
					.mat-cell {
						.named-avatar-wrapper {
							color: $primary-1;
						}
					}
				}
			}
		}
	}
}

.calendar {
	.mat-table {
		.mat-column-candidates {
			width: 10rem;
			padding: 0 1rem;
			text-align: center !important;
		}

		.mat-row {
			.mat-cell {
				border-right: 1px solid $gray-shade-5;
				width: max-content !important;
				@include font-roboto($weight: 500, $lh: 21px, $color: $gray-shade-10, $size: 0.875rem);
			}
		}

		.mat-header-row {
			.mat-header-cell {
				text-align: end;
				padding-right: 1.25rem;
				
				@include font-roboto($weight: 500, $lh: 21px, $color: $gray-shade-10, $size: 18px);
				.day {
					// padding-right: 2rem;
					// border-right: 1px solid $gray-shade-5;
					@include font-roboto($weight: 500, $lh: 21px, $color: $gray-shade-7, $size: 18px);
				}
			}
		}
	}

	.pagination-container {
		.wrapper {
			gap: 1rem !important;
		}
	}
}
