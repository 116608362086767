@import "../variables";
@import "../base/mixins";

.mat-datepicker-content {
	padding: 1.25rem;
	border: 1px solid $gray-shade-5 !important;
	border-radius: 8px !important;
	box-shadow: 0 !important;
	min-height: 21.8125rem !important;

	&-container-with-custom-header .mat-calendar {
		display: flex;
		flex-direction: column;
		gap: 1rem;

		&-table {
			&-header-divider::after {
				display: none !important;
			}

			.mat-calendar-body {
				&-selected {
					color: $primary-2 !important;
					border: 0 !important;
					background-color: $primary-1;
				}
			}

			&-header th {
				padding: 0 0 0.25rem !important;
				font-size: 1rem !important;
				font-weight: 500 !important;
				color: #344054 !important;
			}
		}
	}
}
