@import "../variables";
@import "../base/font";
@import "../base/mixins";

.mat-snack-bar-container {
	width: fit-content !important;
	box-shadow: none;
	border-radius: 8px;
	padding: 0 !important;
	position: relative;
	border: 1px solid $primary-1;
	background: $secondary-shade-6;
	min-width: 0;

	@include font-roboto($weight: 400, $size: 0.875rem, $lh: 150%);

	color: $gray-shade-10;
}

.snackbar-success {
	border: $success-3 1px solid;
	color: $success-3;
	background-color: $success-2;
	min-width: 100px !important;

	.close-icon-success {
		.mat-icon {
			svg {
				path {
					stroke: $success-3 !important;
				}
			}
		}
	}
}

.snackbar-error {
	border: $error-1 1px solid;
	color: $error-1;
	background-color: $error-2;
	min-width: 100px !important;

	.close-icon-error {
		.mat-icon {
			svg {
				path {
					stroke: $error-1 !important;
				}
			}
		}
	}
}

.snackbar-warning {
	border: $warning-1 1px solid;
	color: $warning-3;
	background-color: $warning-2;
	min-width: 10% !important;

	.close-icon-warning {
		.mat-icon {
			svg {
				path {
					stroke: $warning-3 !important;
				}
			}
		}
	}
}
