@import "../base/z-index";
@import "../variables";

mat-progress-bar {
	position: absolute;

	// z-index: map-get($map: $zindex, $key: loading-overlay);
}

.table-loader {
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
}

.mat-progress-spinner {
	// z-index: map-get($map: $zindex, $key: loading-overlay);
	display: flex;
	align-items: center;
	justify-content: center;

	.loader-text {
		color: var(--text-primary-color);
		margin-top: 1rem;
	}
}

.overlay {
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	position: absolute;

	// z-index: map.get($map: $zindex, $key: loading-overlay);
	background-color: rgba($color: $primary-3, $alpha: 50%);
	backdrop-filter: blur(5px);
}
